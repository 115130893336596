import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { GET_GALLERIES } from '../graphql/queries'; // Correct path to GraphQL queries
import { Container, Box, Typography, Paper, Grid } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { DndContext, closestCenter } from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';
import { SortableContext, rectSortingStrategy } from '@dnd-kit/sortable';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { restrictToVerticalAxis, restrictToParentElement } from '@dnd-kit/modifiers';
import GalleryCreate from './Gallery/GalleryCreate'; // Ensure this path is correct
import Spinner from './Gallery/common/Spinner'; // Ensure this path is correct
import SortableGalleryItem from './Gallery/SortableGalleryItem'; // Ensure this path is correct
import PhotoSwipeGallery from './Gallery/PhotoSwipeGallery'; // Ensure this path is correct

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    background: {
      default: '#121212',
      paper: '#1D1D1D',
    },
    text: {
      primary: '#ffffff',
    },
  },
});

const GalleryGrid = () => {
  const { loading, error, data } = useQuery(GET_GALLERIES);
  const [galleries, setGalleries] = useState([]);
  const [isCreating, setIsCreating] = useState(false);

  useEffect(() => {
    if (data && data.galleries) {
      setGalleries(data.galleries);
    }
  }, [data]);

  const handleCreateGallery = () => {
    setIsCreating(true);
  };

  const handleGalleryCreated = (newGallery) => {
    setGalleries((prevGalleries) => [newGallery, ...prevGalleries]);
    setIsCreating(false);
  };

  const handleOnDragEnd = (event) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      setGalleries((items) => {
        const oldIndex = items.findIndex((item) => item.id === active.id);
        const newIndex = items.findIndex((item) => item.id === over.id);

        return arrayMove(items, oldIndex, newIndex);
      });
    }
  };

  if (loading) return <Spinner />;
  if (error) return <Typography color="error">Error loading galleries</Typography>;

  return (
    <ThemeProvider theme={darkTheme}>
      <Container className="gallery-grid" maxWidth="lg">
        <DndContext
          collisionDetection={closestCenter}
          onDragEnd={handleOnDragEnd}
          modifiers={[restrictToVerticalAxis, restrictToParentElement]}
        >
          <SortableContext items={galleries.map((gallery) => gallery.id)} strategy={rectSortingStrategy}>
            <Grid container spacing={3}>
              {/* Create New Gallery Button as a Grid Item */}
              <Grid item xs={12} sm={6} md={4}>
                {isCreating ? (
                  <GalleryCreate onGalleryCreated={handleGalleryCreated} />
                ) : (
                  <Paper
                    elevation={3}
                    onClick={handleCreateGallery}
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '200px',
                      cursor: 'pointer',
                      transition: 'border-color 0.3s',
                      '&:hover': {
                        borderColor: 'gray',
                      },
                      backgroundColor: darkTheme.palette.background.paper,
                      color: darkTheme.palette.text.primary,
                    }}
                  >
                    <Box textAlign="center">
                      <AddIcon color="action" style={{ fontSize: 40 }} />
                      <Typography color="textSecondary" variant="h6">
                        Create New Gallery
                      </Typography>
                    </Box>
                  </Paper>
                )}
              </Grid>

              {/* Render Gallery Items */}
              {galleries.map((gallery) => (
                <SortableGalleryItem key={gallery.id} id={gallery.id}>
                  <Grid item xs={12} sm={6} md={4}>
                    <PhotoSwipeGallery photos={gallery.photos} galleryId={gallery.id} />
                  </Grid>
                </SortableGalleryItem>
              ))}
            </Grid>
          </SortableContext>
        </DndContext>
      </Container>
    </ThemeProvider>
  );
};

export default GalleryGrid;
