import { ApolloClient, InMemoryCache, createHttpLink, split } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';

// Load environment variables for production
const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT || 'https://jesuschristourking.com/graphql';
const WS_ENDPOINT = process.env.REACT_APP_WS_ENDPOINT || 'wss://jesuschristourking.com/graphql';
const TOKEN_KEY = process.env.REACT_APP_TOKEN_KEY || 'auth_token';

// HTTP link for queries and mutations
const httpLink = createHttpLink({
  uri: API_ENDPOINT,
});

// Authentication link to add token to HTTP headers
const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem(TOKEN_KEY);
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    }
  };
});

// Error link to handle errors globally
const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) => {
      console.error(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
    });
  }
  if (networkError) {
    console.error(`[Network error]: ${networkError}`);
  }
});

// WebSocket link for subscriptions (handles real-time updates)
const wsLink = new WebSocketLink({
  uri: WS_ENDPOINT,  // Using wss:// for secure WebSocket connections
  options: {
    reconnect: true,  // Automatically reconnect WebSocket if connection drops
    connectionParams: {
      authToken: localStorage.getItem(TOKEN_KEY),  // Pass auth token in WebSocket connection
    },
  },
});

// Split link to use WebSocket for subscriptions and HTTP for queries/mutations
const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,  // Use WebSocket for subscriptions
  authLink.concat(errorLink.concat(httpLink))  // Use HTTP for queries and mutations
);

// Apollo Client setup
const client = new ApolloClient({
  link: splitLink,
  cache: new InMemoryCache(),
});

// Export the Apollo client
export default client;
