import React from 'react';
import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useUser } from '../context/UserProvider';

// Spinner component to indicate loading state
const Spinner = () => <div>Loading...</div>;

const ProtectedRoute = ({ children, isAdmin = false }) => {
  const { user, isLoading } = useUser();

  // Log the user state for debugging
  console.log("ProtectedRoute: Checking user authentication status:", user);

  // Show a loading spinner if the user data is still being fetched
  if (isLoading) {
    console.log("ProtectedRoute: User data is still being fetched");
    return <Spinner />;
  }

  // Redirect to login page if the user is not authenticated
  if (!user) {
    console.log("ProtectedRoute: User not authenticated, redirecting to login");
    return <Navigate to="/login" replace />;
  }

  // Redirect to dashboard if the route requires admin access and the user is not an admin
  if (isAdmin && !user.is_superuser) {
    console.log("ProtectedRoute: User is not an admin, redirecting to dashboard");
    return <Navigate to="/dashboard" replace />;
  }

  // If the user is authenticated and has the required access, render the child components
  console.log("ProtectedRoute: User authenticated, rendering children");
  return children;
};

ProtectedRoute.propTypes = {
  children: PropTypes.node.isRequired,
  isAdmin: PropTypes.bool,
};

export default ProtectedRoute;
