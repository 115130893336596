import React, { createContext, useContext, useReducer, useEffect, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { Cookies } from 'react-cookie';
import { LOGIN_USER, REFRESH_TOKEN } from '../graphql/mutations'; // Updated import to use mutations file
import { FETCH_USER_DATA } from '../graphql/queries'; // Corrected to import from queries

// Create User Context
export const UserContext = createContext();
export const useUser = () => useContext(UserContext);

// Initialize cookies
const cookies = new Cookies();

// Initial state for the user context
const initialState = {
  user: null,
  token: cookies.get('auth_token') || sessionStorage.getItem('auth_token'),
  isLoading: true,
};

// Reducer to manage user state
const reducer = (state, action) => {
  switch (action.type) {
    case 'LOGIN':
      return { ...state, user: action.payload.user, token: action.payload.token, isLoading: false };
    case 'LOGOUT':
      return { ...state, user: null, token: null, isLoading: false };
    case 'SET_USER':
      return { ...state, user: action.payload, isLoading: false };
    case 'SET_LOADING':
      return { ...state, isLoading: action.payload };
    default:
      return state;
  }
};

const UserProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const navigate = useNavigate();

  // Apollo hook to log in the user
  const [loginUser] = useMutation(LOGIN_USER);

  // Apollo hook to fetch user data
  const { refetch } = useQuery(FETCH_USER_DATA, {
    skip: !state.token,
    onCompleted: (data) => {
      if (data && data.user) {
        dispatch({ type: 'SET_USER', payload: data.user });
      }
    },
    onError: (error) => {
      console.error('Error fetching user data:', error);
      logout();
    },
  });

  // Apollo hook to refresh the auth token
  const [refreshAuthToken] = useMutation(REFRESH_TOKEN);

  // Logout function to clear tokens and reset state
  const logout = useCallback(() => {
    dispatch({ type: 'LOGOUT' });
    cookies.remove('auth_token', { path: '/' });
    cookies.remove('refresh_token', { path: '/' });
    localStorage.removeItem('auth_token');
    localStorage.removeItem('refresh_token');
    sessionStorage.removeItem('auth_token');
    sessionStorage.removeItem('refresh_token');
    navigate('/login');
  }, [navigate]);

  // Effect to check authentication status on mount or when the token changes
  useEffect(() => {
    const checkAuth = async () => {
      if (state.token) {
        try {
          await refetch();
        } catch (error) {
          console.error('Error fetching user data:', error);
          logout();
        }
      } else {
        dispatch({ type: 'LOGOUT' });
      }
    };

    checkAuth();
  }, [logout, state.token, refetch]);

  // Function to log in the user
  const login = useCallback(async (email, password, rememberMe) => {
    try {
      const { data } = await loginUser({ variables: { email, password } });
      const userData = data.login; // Ensure this matches your GraphQL response

      // Store tokens securely based on rememberMe preference
      const storage = rememberMe ? localStorage : sessionStorage;
      storage.setItem('auth_token', userData.token);
      storage.setItem('refresh_token', userData.refreshToken);

      cookies.set('auth_token', userData.token, {
        path: '/',
        secure: process.env.NODE_ENV === 'production',
        sameSite: 'Lax',
        httpOnly: true,
      });
      cookies.set('refresh_token', userData.refreshToken, {
        path: '/',
        secure: process.env.NODE_ENV === 'production',
        sameSite: 'Lax',
        httpOnly: true,
      });

      dispatch({ type: 'LOGIN', payload: userData });
      navigate(userData.user.is_superuser ? '/builder' : '/dashboard');
    } catch (error) {
      console.error('Login error:', error);
      throw error;
    }
  }, [loginUser, dispatch, navigate]);

  // Function to refresh the token
  const refreshToken = useCallback(async () => {
    try {
      const currentRefreshToken = cookies.get('refresh_token') || sessionStorage.getItem('refresh_token') || localStorage.getItem('refresh_token');
      if (!currentRefreshToken) throw new Error('No refresh token available');

      const { data } = await refreshAuthToken({ variables: { refreshToken: currentRefreshToken } });
      const newToken = data.refreshToken; // Ensure this matches your GraphQL response

      cookies.set('auth_token', newToken, {
        path: '/',
        secure: process.env.NODE_ENV === 'production',
        sameSite: 'Lax',
        httpOnly: true,
      });

      if (localStorage.getItem('refresh_token')) {
        localStorage.setItem('auth_token', newToken);
      } else {
        sessionStorage.setItem('auth_token', newToken);
      }

      dispatch({ type: 'LOGIN', payload: { token: newToken, user: state.user } });
    } catch (error) {
      console.error('Error refreshing token:', error);
      logout();
    }
  }, [logout, state.user, refreshAuthToken]);

  // Memoized context value to avoid unnecessary re-renders
  const contextValue = useMemo(() => ({
    ...state,
    login,
    logout,
    refreshToken,
  }), [state, login, logout, refreshToken]);

  return (
    <UserContext.Provider value={contextValue}>
      {children}
    </UserContext.Provider>
  );
};

export default UserProvider;
