import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import { CookiesProvider } from 'react-cookie';
import { Provider } from 'react-redux';
import store from './store/store';
import client from './apolloClient';
import './index.css';
import App from './App';
import Home from './pages/Home';
import FormikRegister from './components/FormikRegister';
import FormikLogin from './components/FormikLogin';
import Dashboard from './pages/Dashboard';
import BuilderComponent from './components/BuilderComponent';
import GalleryGrid from './components/GalleryGrid';
import ProtectedRoute from './components/ProtectedRoute';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ApolloProvider client={client}>
        <CookiesProvider>
          <Router>
            <App>
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/register" element={<FormikRegister />} />
                <Route path="/login" element={<FormikLogin />} />
                <Route path="/dashboard" element={
                  <ProtectedRoute>
                    <Dashboard />
                  </ProtectedRoute>
                } />
                <Route path="/admin/*" element={
                  <ProtectedRoute isAdmin={true}>
                    <BuilderComponent />
                  </ProtectedRoute>
                } />
                <Route path="/gallery" element={
                  <ProtectedRoute>
                    <GalleryGrid />
                  </ProtectedRoute>
                } />
              </Routes>
            </App>
          </Router>
        </CookiesProvider>
      </ApolloProvider>
    </Provider>
  </React.StrictMode>
);
