// src/components/BuilderComponent.js

import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { Layout, Menu, Button, Spin } from 'antd';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { gql } from '@apollo/client';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Box } from '@mui/material';

const { Header, Content, Sider } = Layout;

// GraphQL query to get components
const GET_COMPONENTS = gql`
  query GetComponents {
    components {
      id
      name
      description
    }
  }
`;

// Draggable Component Template
const DraggableComponent = ({ component }) => {
  const [{ isDragging }, drag] = useDrag({
    type: 'COMPONENT',
    item: { id: component.id },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  });

  return (
    <div
      ref={drag}
      style={{
        opacity: isDragging ? 0.5 : 1,
        padding: '8px',
        border: '1px solid #ccc',
        marginBottom: '8px',
        backgroundColor: '#ffffff',
        cursor: 'move',
      }}
    >
      <h4>{component.name}</h4>
      <p>{component.description}</p>
    </div>
  );
};

// Droppable Area
const DroppableArea = ({ onDrop }) => {
  const [, drop] = useDrop({
    accept: 'COMPONENT',
    drop: (item) => onDrop(item.id),
  });

  return (
    <div
      ref={drop}
      style={{
        minHeight: '200px',
        border: '2px dashed #ccc',
        padding: '16px',
        backgroundColor: '#fafafa',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      Drop components here
    </div>
  );
};

const BuilderComponent = () => {
  const { loading, error, data } = useQuery(GET_COMPONENTS);
  const [selectedComponents, setSelectedComponents] = useState([]);

  if (loading) return <Spin tip="Loading..." />;
  if (error) return <p>Error loading components</p>;

  const handleDrop = (componentId) => {
    const component = data.components.find((c) => c.id === componentId);
    setSelectedComponents((prev) => [...prev, component]);
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <ThemeProvider theme={createTheme()}>
        <Layout style={{ minHeight: '100vh' }}>
          <Sider width={200} className="site-layout-background">
            <Menu mode="inline" defaultSelectedKeys={['1']} style={{ height: '100%', borderRight: 0 }}>
              <Menu.Item key="1">Component Templates</Menu.Item>
              <Menu.Item key="2">API Endpoints</Menu.Item>
            </Menu>
          </Sider>
          <Layout style={{ padding: '0 24px 24px' }}>
            <Header className="site-layout-sub-header-background" style={{ padding: 0, backgroundColor: '#fff' }}>
              <Button type="primary" style={{ marginLeft: 16 }}>Add New Component</Button>
            </Header>
            <Content style={{ padding: 24, margin: 0, minHeight: 280 }}>
              <h1>Builder Component</h1>
              <Box display="flex" gap={2}>
                <Box>
                  {data.components.map((component) => (
                    <DraggableComponent key={component.id} component={component} />
                  ))}
                </Box>
                <DroppableArea onDrop={handleDrop} />
              </Box>
              <div style={{ marginTop: '16px' }}>
                <h2>Selected Components</h2>
                {selectedComponents.map((component) => (
                  <div key={component.id} style={{ padding: '8px', border: '1px solid #ccc', marginBottom: '8px' }}>
                    <h4>{component.name}</h4>
                    <p>{component.description}</p>
                  </div>
                ))}
              </div>
            </Content>
          </Layout>
        </Layout>
      </ThemeProvider>
    </DndProvider>
  );
};

export default BuilderComponent;
