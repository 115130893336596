import React, { useState } from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useMutation } from '@apollo/client';
import { LOGIN_USER } from '../graphql/mutations'; // Correct import from mutations.js
import { TextField, Button, Typography, Container, Box, FormControlLabel, Checkbox, IconButton } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useUser } from '../context/UserProvider'; // Your custom context provider for authentication
import { Link } from 'react-router-dom'; // Import Link for navigation

// Dark theme setup
const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    background: {
      default: '#121212',
      paper: '#1D1D1D',
    },
    text: {
      primary: '#ffffff',
    },
  },
  typography: {
    fontFamily: 'Roboto, sans-serif',
  },
});

// Validation schema using Yup
const validationSchema = Yup.object({
  email: Yup.string().email('Invalid email address').required('Email is required'),
  password: Yup.string().required('Password is required'),
});

function FormikLogin() {
  const { login } = useUser(); // Custom login function from UserProvider
  const [error, setError] = useState(null); // State for managing error messages
  const [showPassword, setShowPassword] = useState(false);

  const [loginUser, { loading }] = useMutation(LOGIN_USER, {
    onCompleted: (data) => {
      const { token, refresh_token, user } = data.login_user; // Correct data access
      login({ token, refresh_token, user, rememberMe: false });
    },
    onError: (error) => {
      // Handle error in login mutation
      console.error('Login error', error);
      setError('Login failed. Please check your credentials and try again.');
    },
  });

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <ThemeProvider theme={darkTheme}>
      <Container maxWidth="sm" style={{ paddingTop: '2rem' }}>
        <Box component="div" p={4} bgcolor="background.paper" borderRadius={2}>
          <Typography variant="h4" align="center" gutterBottom>
            Login
          </Typography>
          {error && <Typography color="error">{error}</Typography>}
          <Formik
            initialValues={{ email: '', password: '', rememberMe: false }}
            validationSchema={validationSchema}
            onSubmit={async (values, { setSubmitting, setFieldError }) => {
              try {
                await loginUser({ variables: { email: values.email, password: values.password } });
              } catch (error) {
                console.error('Login error:', error);
                setFieldError('email', 'Login failed. Please check your credentials and try again.');
              } finally {
                setSubmitting(false);
              }
            }}
          >
            {({ isSubmitting }) => (
              <Form>
                <Box mb={3}>
                  <Field
                    as={TextField}
                    label="Email"
                    name="email"
                    type="email"
                    fullWidth
                    variant="outlined"
                    margin="normal"
                    required
                    autoComplete="username"
                    InputLabelProps={{
                      style: { color: darkTheme.palette.text.primary },
                    }}
                    InputProps={{
                      style: { color: darkTheme.palette.text.primary },
                    }}
                  />
                  <ErrorMessage name="email" component="div" className="text-red-500" />
                </Box>
                <Box mb={3} position="relative">
                  <Field
                    as={TextField}
                    label="Password"
                    name="password"
                    type={showPassword ? 'text' : 'password'}
                    fullWidth
                    variant="outlined"
                    margin="normal"
                    required
                    autoComplete="current-password"
                    InputLabelProps={{
                      style: { color: darkTheme.palette.text.primary },
                    }}
                    InputProps={{
                      style: { color: darkTheme.palette.text.primary },
                    }}
                  />
                  <IconButton
                    onClick={togglePasswordVisibility}
                    style={{ position: 'absolute', right: 10, top: 30 }}
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                  <ErrorMessage name="password" component="div" className="text-red-500" />
                </Box>
                <Box mb={3}>
                  <FormControlLabel
                    control={<Field as={Checkbox} name="rememberMe" color="primary" />}
                    label="Remember me"
                    style={{ color: darkTheme.palette.text.primary }}
                  />
                </Box>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth
                  style={{ marginTop: '16px' }}
                  disabled={isSubmitting || loading} // Disable button when loading
                >
                  {loading ? 'Logging in...' : 'Login'}
                </Button>
              </Form>
            )}
          </Formik>
          <Typography variant="body2" align="center" style={{ marginTop: '1rem', color: darkTheme.palette.text.primary }}>
            Not a member? <Link to="/register" style={{ color: '#3f51b5' }}>Register</Link>
          </Typography>
          <Typography variant="body2" align="center" style={{ marginTop: '1rem', color: darkTheme.palette.text.primary }}>
            <Link to="/forgot-password" style={{ color: '#3f51b5' }}>Forgot your password?</Link>
          </Typography>
        </Box>
      </Container>
    </ThemeProvider>
  );
}

export default FormikLogin;
