import React, { useState, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import { FilePond, registerPlugin } from 'react-filepond';
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginImageEdit from 'filepond-plugin-image-edit';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  Typography,
  TextField,
  Button,
  Box,
  Paper,
} from '@mui/material';
import { DndContext, useSensor, useSensors, PointerSensor, KeyboardSensor } from '@dnd-kit/core';
import { arrayMove, SortableContext, sortableKeyboardCoordinates } from '@dnd-kit/sortable';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import SortablePhotoItem from './SortableGalleryItem'; // Ensure this path is correct

// Register FilePond plugins
registerPlugin(FilePondPluginImagePreview, FilePondPluginImageEdit);

const CREATE_GALLERY_MUTATION = gql`
  mutation CreateGallery($title: String!, $description: String!, $isPublic: Boolean!, $photos: [Upload!]!) {
    createGallery(input: { title: $title, description: $description, isPublic: $isPublic, photos: $photos }) {
      gallery {
        id
        title
        description
        coverImage {
          id
          url
        }
        photos {
          id
          title
          url
        }
      }
      success
      errors
    }
  }
`;

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    background: {
      default: '#121212',
      paper: '#1D1D1D',
    },
    text: {
      primary: '#ffffff',
    },
  },
  typography: {
    fontFamily: 'Roboto, sans-serif',
  },
});

const GalleryCreate = ({ onGalleryCreated }) => {
  const [files, setFiles] = useState([]);
  const [photos, setPhotos] = useState([]);
  const [photoOrder, setPhotoOrder] = useState([]);
  const [uploadProgress, setUploadProgress] = useState({});
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [createGallery] = useMutation(CREATE_GALLERY_MUTATION);

  useEffect(() => {
    // Set photo order based on uploaded files
    setPhotoOrder(photos.map((photo) => photo.id));
  }, [photos]);

  const handleCreateGallery = async (e) => {
    e.preventDefault();
    try {
      const uploadedPhotos = files.map((file) => file.file); // Use FilePond's file object
      const { data } = await createGallery({
        variables: {
          title,
          description,
          isPublic: true,
          photos: uploadedPhotos,
        },
      });

      if (data.createGallery.success) {
        toast.success('Gallery created successfully');
        onGalleryCreated(data.createGallery.gallery);
      } else {
        toast.error(`Failed to create gallery: ${data.createGallery.errors.join(', ')}`);
      }
    } catch (error) {
      toast.error(`Failed to create gallery: ${error.message}`);
    }
  };

  const handleProcess = (fieldName, file, metadata, load, error, progress, abort) => {
    // Simulate upload progress
    let progressInterval = setInterval(() => {
      progress(true, Math.min(100, (uploadProgress[file.id] || 0) + 10), 100);
      setUploadProgress((prev) => ({
        ...prev,
        [file.id]: Math.min(100, (prev[file.id] || 0) + 10),
      }));
      if ((uploadProgress[file.id] || 0) >= 100) {
        clearInterval(progressInterval);
      }
    }, 500);

    return {
      abort: () => {
        clearInterval(progressInterval);
        abort();
      },
    };
  };

  const handleDragEnd = (event) => {
    const { active, over } = event;
    if (active.id !== over.id) {
      setPhotoOrder((items) => {
        const oldIndex = items.indexOf(active.id);
        const newIndex = items.indexOf(over.id);
        return arrayMove(items, oldIndex, newIndex);
      });
    }
  };

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  return (
    <ThemeProvider theme={darkTheme}>
      <Box>
        <Paper elevation={3} style={{ padding: '16px', backgroundColor: darkTheme.palette.background.paper }}>
          <Typography variant="h4" align="center" gutterBottom>
            Create Gallery
          </Typography>
          <Box mb={3}>
            <TextField
              label="Gallery Title"
              fullWidth
              variant="outlined"
              margin="normal"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              InputLabelProps={{
                style: { color: darkTheme.palette.text.primary },
              }}
              InputProps={{
                style: { color: darkTheme.palette.text.primary },
              }}
            />
          </Box>
          <Box mb={3}>
            <TextField
              label="Gallery Description"
              fullWidth
              variant="outlined"
              margin="normal"
              multiline
              rows={4}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              InputLabelProps={{
                style: { color: darkTheme.palette.text.primary },
              }}
              InputProps={{
                style: { color: darkTheme.palette.text.primary },
              }}
            />
          </Box>
          <FilePond
            files={files}
            allowMultiple={true}
            maxFiles={10}
            server={{
              process: handleProcess,
              revert: (fileId, load) => {
                setFiles((prevFiles) => prevFiles.filter((file) => file.id !== fileId));
                load();
              },
            }}
            name="photos"
            onupdatefiles={setFiles}
            imageCropAspectRatio="1:1"
            stylePanelAspectRatio="1:1"
            acceptedFileTypes={['image/*']}
            allowFileTypeValidation={true}
            allowFileSizeValidation={true}
            maxFileSize="5MB"
            onremovefile={(error, file) => {
              setPhotos((prevPhotos) => prevPhotos.filter((p) => p.id !== file.id));
            }}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleCreateGallery}
            style={{ marginTop: '16px' }}
          >
            Create Gallery
          </Button>

          <DndContext sensors={sensors} onDragEnd={handleDragEnd}>
            <SortableContext items={photoOrder}>
              <Box display="flex" flexWrap="wrap" gap={2} mt={2}>
                {photoOrder.map((photoId) => {
                  const photo = photos.find((p) => p.id === photoId);
                  return (
                    <SortablePhotoItem
                      key={photo.id}
                      id={photo.id}
                      photo={photo}
                    />
                  );
                })}
              </Box>
            </SortableContext>
          </DndContext>
        </Paper>
      </Box>
    </ThemeProvider>
  );
};

export default GalleryCreate;
