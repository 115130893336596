// src/graphql/fragments.js
import { gql } from '@apollo/client';

// User fragments
export const USER_FIELDS = gql`
  fragment UserFields on UserType {
    id
    email
    firstName
    lastName
    role
    isActive
  }
`;

export const PROFILE_FIELDS = gql`
  fragment ProfileFields on ProfileType {
    id
    user {
      ...UserFields
    }
    bio
    location
    birthDate
    picture
  }
  ${USER_FIELDS}
`;

// Photo fragments
export const PHOTO_FIELDS = gql`
  fragment PhotoFields on PhotoType {
    id
    title
    description
    isPublic
    createdAt
    user {
      ...UserFields
    }
    tags {
      id
      name
    }
    category {
      id
      name
    }
    comments {
      id
      content
      createdAt
    }
    originalImage
    thumbnailImage
    smallImage
    mediumImage
    largeImage
    audioRecording
  }
  ${USER_FIELDS}
`;

// Gallery fragments
export const GALLERY_FIELDS = gql`
  fragment GalleryFields on GalleryType {
    id
    title
    description
    isPublic
    createdAt
    user {
      ...UserFields
    }
    photos {
      ...PhotoFields
    }
  }
  ${USER_FIELDS}
  ${PHOTO_FIELDS}
`;
