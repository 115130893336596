import React, { useEffect, useRef, useState } from 'react';
import PhotoSwipeLightbox from 'photoswipe/lightbox';
import 'photoswipe/style.css';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Paper, Typography, Box, IconButton, Checkbox, Button } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { DndContext, useSensor, useSensors, PointerSensor, KeyboardSensor } from '@dnd-kit/core';
import { arrayMove, SortableContext, sortableKeyboardCoordinates, useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    background: {
      default: '#121212',
      paper: '#1D1D1D',
    },
    text: {
      primary: '#ffffff',
    },
  },
});

const SortablePhotoItem = ({ photo, index, selectedPhotos, onSelect, onEdit, onDelete }) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: photo.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition: transition || 'transform 0.3s', // Define the transition property
    border: selectedPhotos.includes(photo.id) ? '2px solid #f50057' : '2px solid transparent',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    '&:hover': {
      transform: 'scale(1.05)',
    },
  };

  return (
    <a
      href={photo.original_image_url}
      data-pswp-width={1024}
      data-pswp-height={768}
      data-pswp-title={photo.title}
      style={{ position: 'relative', display: 'block', textDecoration: 'none' }}
      ref={setNodeRef}
      {...attributes}
      {...listeners}
    >
      <Paper elevation={3} sx={style}>
        <Checkbox
          checked={selectedPhotos.includes(photo.id)}
          onChange={() => onSelect(photo.id)}
          color="primary"
          sx={{ position: 'absolute', top: 0, left: 0 }}
        />
        <img
          src={photo.thumbnail_image_url}
          alt={photo.title}
          style={{ width: '100%', height: 'auto', borderRadius: '8px 8px 0 0' }}
          loading="lazy"
          onError={(e) => (e.target.src = '/fallback-image.jpg')}
          role="button"
          aria-label={`Open ${photo.title}`}
        />
        <Box p={2}>
          <Typography variant="h6">{photo.title}</Typography>
          <Typography variant="body2" color="textSecondary">
            {photo.description}
          </Typography>
          <Box display="flex" justifyContent="space-between" mt={2}>
            <IconButton color="primary" onClick={() => onEdit(photo.id)} aria-label={`Edit ${photo.title}`}>
              <EditIcon />
            </IconButton>
            <IconButton color="secondary" onClick={() => onDelete(photo.id)} aria-label={`Delete ${photo.title}`}>
              <DeleteIcon />
            </IconButton>
          </Box>
        </Box>
      </Paper>
    </a>
  );
};

const PhotoSwipeGallery = ({ photos = [], galleryId, onDelete, onEdit, onBulkDelete }) => {
  const galleryRef = useRef(null);
  const lightboxRef = useRef(null);
  const [selectedPhotos, setSelectedPhotos] = useState([]);
  const [photoOrder, setPhotoOrder] = useState(photos.map((photo) => photo.id));

  useEffect(() => {
    if (lightboxRef.current) {
      lightboxRef.current.destroy();
    }

    if (galleryRef.current) {
      const lightbox = new PhotoSwipeLightbox({
        gallery: `#${galleryId}`,
        children: 'a',
        pswpModule: () => import('photoswipe'),
        loop: true,
        padding: { top: 50, bottom: 50 },
        showHideOpacity: true,
        bgOpacity: 0.85,
        closeOnVerticalDrag: false,
        clickToCloseNonZoomable: false,
      });

      lightbox.init();
      lightboxRef.current = lightbox;
    }

    return () => {
      if (lightboxRef.current) {
        lightboxRef.current.destroy();
      }
    };
  }, [photos, galleryId]);

  useEffect(() => {
    setPhotoOrder(photos.map((photo) => photo.id));
  }, [photos]);

  const handleSelectPhoto = (photoId) => {
    setSelectedPhotos((prevSelected) =>
      prevSelected.includes(photoId) ? prevSelected.filter((id) => id !== photoId) : [...prevSelected, photoId]
    );
  };

  const handleDeleteSelected = () => {
    if (onBulkDelete) {
      onBulkDelete(selectedPhotos);
    }
    setSelectedPhotos([]);
  };

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleDragEnd = (event) => {
    const { active, over } = event;
    if (active.id !== over.id) {
      setPhotoOrder((items) => {
        const oldIndex = items.indexOf(active.id);
        const newIndex = items.indexOf(over.id);
        return arrayMove(items, oldIndex, newIndex);
      });
    }
  };

  return (
    <ThemeProvider theme={darkTheme}>
      <DndContext sensors={sensors} onDragEnd={handleDragEnd}>
        <SortableContext items={photoOrder}>
          <div id={galleryId} ref={galleryRef} className="pswp-gallery">
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
              {photoOrder.map((photoId) => {
                const photo = photos.find((p) => p.id === photoId);
                return (
                  <SortablePhotoItem
                    key={photo.id}
                    photo={photo}
                    index={photoOrder.indexOf(photo.id)}
                    selectedPhotos={selectedPhotos}
                    onSelect={handleSelectPhoto}
                    onEdit={onEdit}
                    onDelete={onDelete}
                  />
                );
              })}
            </div>
          </div>
        </SortableContext>
      </DndContext>
      {selectedPhotos.length > 0 && (
        <Box mt={2} display="flex" justifyContent="space-between">
          <Button variant="contained" color="secondary" onClick={handleDeleteSelected}>
            Delete Selected
          </Button>
        </Box>
      )}
    </ThemeProvider>
  );
};

export default PhotoSwipeGallery;
