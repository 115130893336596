// src/components/home.js
import React from 'react';
import { Container, Box, Typography } from '@mui/material';
import DynamicSlider from '../components/DynamicSlider';  // Import the DynamicSlider component
import { createTheme, ThemeProvider } from '@mui/material/styles';

// Define a custom dark theme for the homepage
const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    background: {
      default: '#121212',
      paper: '#1D1D1D',
    },
    text: {
      primary: '#ffffff',
    },
  },
});

const Home = () => {
  return (
    <ThemeProvider theme={darkTheme}>
      <Container maxWidth="lg">
        {/* Welcome Header */}
        <Box textAlign="center" py={5}>
          <Typography variant="h2" gutterBottom>
            Welcome to Our Website
          </Typography>
          <Typography variant="h5" color="textSecondary">
            Explore the best photos in our dynamic slider below
          </Typography>
        </Box>

        {/* Dynamic Slider */}
        <Box my={4}>
          <DynamicSlider /> {/* Integrate the DynamicSlider component */}
        </Box>

        {/* Additional Content Section */}
        <Box textAlign="center" py={5}>
          <Typography variant="h4" gutterBottom>
            More Features
          </Typography>
          <Typography variant="body1" color="textSecondary">
            This is a placeholder for additional content. You can add more sections here as needed.
          </Typography>
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default Home;
