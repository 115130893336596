// src/pages/Dashboard.js

import React, { useState } from 'react';
import { Container, Grid, Typography, Button, Box } from '@mui/material';
import { DndContext, closestCenter } from '@dnd-kit/core';
import { SortableContext, rectSortingStrategy } from '@dnd-kit/sortable';
import { arrayMove } from '@dnd-kit/sortable';
import { restrictToParentElement } from '@dnd-kit/modifiers'; // Removed restrictToGrid import
import SortableDashboardItem from '../components/SortableDashboardItem';
import AddIcon from '@mui/icons-material/Add';
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import PeopleIcon from '@mui/icons-material/People';
import { Link } from 'react-router-dom'; // Import Link for routing

const Dashboard = () => {
  const [items, setItems] = useState([
    { id: '1', icon: <PhotoLibraryIcon />, label: 'Gallery', link: '/gallery' },
    { id: '2', icon: <VideoLibraryIcon />, label: 'Videos', link: '/videos' },
    { id: '3', icon: <ShoppingCartIcon />, label: 'E-commerce', link: '/shop' },
    { id: '4', icon: <PeopleIcon />, label: 'Community', link: '/community' },
  ]);

  const handleOnDragEnd = (event) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      setItems((items) => {
        const oldIndex = items.findIndex((item) => item.id === active.id);
        const newIndex = items.findIndex((item) => item.id === over.id);

        return arrayMove(items, oldIndex, newIndex);
      });
    }
  };

  return (
    <Container maxWidth="md">
      <Box textAlign="center" my={4}>
        <Typography variant="h4" gutterBottom>
          Dashboard
        </Typography>
      </Box>
      <DndContext
        collisionDetection={closestCenter}
        onDragEnd={handleOnDragEnd}
        modifiers={[restrictToParentElement]} // Removed restrictToGrid from modifiers
      >
        <SortableContext items={items.map((item) => item.id)} strategy={rectSortingStrategy}>
          <Grid container spacing={3}>
            {items.map((item) => (
              <SortableDashboardItem key={item.id} id={item.id} icon={item.icon} label={item.label} link={item.link} />
            ))}
          </Grid>
        </SortableContext>
      </DndContext>
      <Box textAlign="center" mt={4}>
        <Button component={Link} to="/gallery" variant="contained" startIcon={<AddIcon />}>
          Open Gallery
        </Button>
      </Box>
    </Container>
  );
};

export default Dashboard;
