import { createSlice } from '@reduxjs/toolkit';

const userSlice = createSlice({
  name: 'user',
  initialState: { user: null, token: null, isLoading: true },
  reducers: {
    login(state, action) {
      state.user = action.payload.user;
      state.token = action.payload.token;
      state.isLoading = false;
    },
    logout(state) {
      state.user = null;
      state.token = null;
      state.isLoading = false;
    },
    setUser(state, action) {
      state.user = action.payload;
      state.isLoading = false;
    },
    setLoading(state, action) {
      state.isLoading = action.payload;
    },
  },
});

export const { login, logout, setUser, setLoading } = userSlice.actions;
export default userSlice.reducer;
