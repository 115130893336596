// src/graphql/queries.js
import { gql } from '@apollo/client';
import { USER_FIELDS, PROFILE_FIELDS, PHOTO_FIELDS, GALLERY_FIELDS } from './fragments';

// User queries
export const GET_ME = gql`
  query GetMe {
    me {
      ...UserFields
    }
  }
  ${USER_FIELDS}
`;

export const GET_USER_PROFILE = gql`
  query GetUserProfile($userId: ID!) {
    userProfile(userId: $userId) {
      ...ProfileFields
    }
  }
  ${PROFILE_FIELDS}
`;

export const FETCH_USER_DATA = gql`
  query FetchUserData {
    user {
      ...UserFields
    }
  }
  ${USER_FIELDS}
`;

// Photo queries
export const GET_PHOTOS = gql`
  query GetPhotos($sortBy: PhotoSortEnum, $search: String, $isPublic: Boolean, $tags: [ID], $categoryId: ID) {
    photos(sortBy: $sortBy, search: $search, isPublic: $isPublic, tags: $tags, categoryId: $categoryId) {
      edges {
        node {
          ...PhotoFields
        }
      }
    }
  }
  ${PHOTO_FIELDS}
`;

export const GET_PHOTO = gql`
  query GetPhoto($id: ID!) {
    photo(id: $id) {
      ...PhotoFields
      comments {
        id
        content
        createdAt
        user {
          ...UserFields
        }
      }
      likes {
        id
        user {
          ...UserFields
        }
      }
    }
  }
  ${PHOTO_FIELDS}
  ${USER_FIELDS}
`;

// Gallery queries
export const GET_GALLERIES = gql`
  query GetGalleries($sortBy: GallerySortEnum, $search: String) {
    galleries(sortBy: $sortBy, search: $search) {
      edges {
        node {
          ...GalleryFields
        }
      }
    }
  }
  ${GALLERY_FIELDS}
`;

export const GET_GALLERY = gql`
  query GetGallery($id: ID!) {
    gallery(id: $id) {
      ...GalleryFields
      photos {
        ...PhotoFields
      }
    }
  }
  ${GALLERY_FIELDS}
  ${PHOTO_FIELDS}
`;

// Slider-specific queries
export const GET_SLIDER_PHOTOS = gql`
  query GetSliderPhotos($sortBy: SliderPhotoSortEnum, $search: String) {
    sliderPhotos(sortBy: $sortBy, search: $search) {
      edges {
        node {
          ...PhotoFields
          order
        }
      }
    }
  }
  ${PHOTO_FIELDS}
`;
