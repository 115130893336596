import React from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useMutation, gql } from '@apollo/client';
import { TextField, Button, Typography, Container, Box } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';

// Dark theme setup
const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    background: {
      default: '#121212',
      paper: '#1D1D1D',
    },
    text: {
      primary: '#ffffff',
    },
  },
  typography: {
    fontFamily: 'Roboto, sans-serif',
  },
});

// GraphQL mutation for user registration
const REGISTER_USER = gql`
  mutation RegisterUser($input: CreateUserInput!) {
    createUser(input: $input) {
      success
      errors
      user {
        id
        email
        first_name
        last_name
      }
    }
  }
`;

// Validation schema using Yup
const validationSchema = Yup.object({
  email: Yup.string().email('Invalid email address').required('Email is required'),
  first_name: Yup.string().required('First name is required'),
  last_name: Yup.string().required('Last name is required'),
  password: Yup.string().min(6, 'Password must be at least 6 characters').required('Password is required'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .required('Confirm Password is required'),
  honeypot: Yup.string().max(0, 'Spambots are not allowed'),
});

function FormikRegister() {
  const [registerUser] = useMutation(REGISTER_USER);

  const handleSubmit = async (values, { setFieldError }) => {
    if (values.honeypot) {
      setFieldError('honeypot', 'Spambots are not allowed');
      return;
    }

    try {
      const { data } = await registerUser({
        variables: {
          input: {
            email: values.email,
            first_name: values.first_name,
            last_name: values.last_name,
            password: values.password,
          },
        },
      });
      console.log('User registered:', data);
    } catch (error) {
      console.error('Error registering user:', error);
      if (error.graphQLErrors) {
        error.graphQLErrors.forEach(({ message }) => {
          setFieldError('email', message);
        });
      } else {
        setFieldError('email', 'Registration failed. Please try again.');
      }
    }
  };

  return (
    <ThemeProvider theme={darkTheme}>
      <Container maxWidth="sm" style={{ marginTop: '2rem' }}>
        <Box component="div" p={4} bgcolor="background.paper" borderRadius={2}>
          <Typography variant="h4" align="center" gutterBottom>
            Register
          </Typography>
          <Formik
            initialValues={{ email: '', first_name: '', last_name: '', password: '', confirmPassword: '', honeypot: '' }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ handleChange, handleSubmit, values, errors, touched }) => (
              <Form onSubmit={handleSubmit}>
                <Box mb={2}>
                  <TextField
                    label="First Name"
                    name="first_name"
                    type="text"
                    fullWidth
                    variant="outlined"
                    value={values.first_name}
                    onChange={handleChange}
                    error={touched.first_name && Boolean(errors.first_name)}
                    helperText={touched.first_name && errors.first_name}
                  />
                </Box>
                <Box mb={2}>
                  <TextField
                    label="Last Name"
                    name="last_name"
                    type="text"
                    fullWidth
                    variant="outlined"
                    value={values.last_name}
                    onChange={handleChange}
                    error={touched.last_name && Boolean(errors.last_name)}
                    helperText={touched.last_name && errors.last_name}
                  />
                </Box>
                <Box mb={2}>
                  <TextField
                    label="Email"
                    name="email"
                    type="email"
                    fullWidth
                    variant="outlined"
                    value={values.email}
                    onChange={handleChange}
                    error={touched.email && Boolean(errors.email)}
                    helperText={touched.email && errors.email}
                  />
                </Box>
                <Box mb={2}>
                  <TextField
                    label="Password"
                    name="password"
                    type="password"
                    fullWidth
                    variant="outlined"
                    value={values.password}
                    onChange={handleChange}
                    error={touched.password && Boolean(errors.password)}
                    helperText={touched.password && errors.password}
                  />
                </Box>
                <Box mb={2}>
                  <TextField
                    label="Confirm Password"
                    name="confirmPassword"
                    type="password"
                    fullWidth
                    variant="outlined"
                    value={values.confirmPassword}
                    onChange={handleChange}
                    error={touched.confirmPassword && Boolean(errors.confirmPassword)}
                    helperText={touched.confirmPassword && errors.confirmPassword}
                  />
                </Box>
                <div style={{ display: 'none' }}>
                  <Field name="honeypot" type="text" />
                  <ErrorMessage name="honeypot" component="div" />
                </div>
                <Button type="submit" variant="contained" color="primary" fullWidth>
                  Register
                </Button>
              </Form>
            )}
          </Formik>
        </Box>
      </Container>
    </ThemeProvider>
  );
}

export default FormikRegister;
