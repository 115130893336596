import { gql } from '@apollo/client';
import { USER_FIELDS, PROFILE_FIELDS, PHOTO_FIELDS } from './fragments';

// User mutations
export const CREATE_USER = gql`
  mutation CreateUser($input: CreateUserInput!) {
    createUser(input: $input) {
      success
      errors
      user {
        ...UserFields
      }
    }
  }
  ${USER_FIELDS}
`;

export const REGISTER_USER = gql`
  mutation RegisterUser($input: CreateUserInput!) {
    createUser(input: $input) {
      success
      errors
      user {
        ...UserFields
      }
    }
  }
  ${USER_FIELDS}
`;

export const LOGIN_USER = gql`
  mutation LoginUser($email: String!, $password: String!) {
    tokenAuth(email: $email, password: $password) {
      success
      errors
      token
      refreshToken
      user {
        ...UserFields
      }
    }
  }
  ${USER_FIELDS}
`;

export const FORGOT_PASSWORD = gql`
  mutation ForgotPassword($email: String!) {
    sendPasswordResetEmail(email: $email) {
      success
      errors
    }
  }
`;

export const RESET_PASSWORD = gql`
  mutation ResetPassword($token: String!, $newPassword: String!) {
    resetPassword(token: $token, newPassword: $newPassword) {
      success
      errors
    }
  }
`;

export const UPDATE_PROFILE = gql`
  mutation UpdateProfile($input: UpdateProfileInput!) {
    updateProfile(input: $input) {
      success
      errors
      profile {
        ...ProfileFields
      }
    }
  }
  ${PROFILE_FIELDS}
`;

// Photo mutations
export const CREATE_PHOTO = gql`
  mutation CreatePhoto($input: CreatePhotoInput!) {
    createPhoto(input: $input) {
      photo {
        ...PhotoFields
      }
      success
      errors
    }
  }
  ${PHOTO_FIELDS}
`;

export const UPDATE_PHOTO = gql`
  mutation UpdatePhoto($input: UpdatePhotoInput!) {
    updatePhoto(input: $input) {
      photo {
        ...PhotoFields
      }
      success
      errors
    }
  }
  ${PHOTO_FIELDS}
`;

export const UPLOAD_PHOTOS = gql`
  mutation UploadPhotos($input: [UploadPhotoInput!]!) {
    uploadPhotos(input: $input) {
      photos {
        ...PhotoFields
      }
      success
      errors
    }
  }
  ${PHOTO_FIELDS}
`;

// Slider photo mutations
export const CREATE_SLIDER_PHOTO = gql`
  mutation CreateSliderPhoto($input: CreateSliderPhotoInput!) {
    createSliderPhoto(input: $input) {
      photo {
        ...PhotoFields
        order
      }
      success
      errors
    }
  }
  ${PHOTO_FIELDS}
`;

export const UPDATE_SLIDER_PHOTO = gql`
  mutation UpdateSliderPhoto($input: UpdateSliderPhotoInput!) {
    updateSliderPhoto(input: $input) {
      photo {
        ...PhotoFields
        order
      }
      success
      errors
    }
  }
  ${PHOTO_FIELDS}
`;

// Refresh token mutation
export const REFRESH_TOKEN = gql`
  mutation RefreshToken($refreshToken: String!) {
    refreshToken(refreshToken: $refreshToken) {
      token
      refreshToken
    }
  }
`;
