// src/components/SortableDashboardItem.js
import React from 'react';
import { Grid, Box, Typography } from '@mui/material';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Link } from 'react-router-dom';

const SortableDashboardItem = ({ id, icon, label, link }) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    padding: 2,
    // eslint-disable-next-line no-dupe-keys
    height: '150px',
    maxHeight: '150px',
    backgroundColor: '#1D1D1D',
    color: '#ffffff',
  };

  return (
    <Grid item xs={6} sm={4} ref={setNodeRef} style={style} {...attributes} {...listeners}>
      <Box component={Link} to={link} sx={{ textDecoration: 'none', color: 'inherit' }}>
        <Box mb={1}>{icon}</Box>
        <Typography variant="h6">{label}</Typography>
      </Box>
    </Grid>
  );
};

export default SortableDashboardItem;
