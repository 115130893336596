import React, { useState, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { Formik, Form, Field } from 'formik';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import PhotoSwipeLightbox from 'photoswipe/lightbox';
import 'photoswipe/style.css';
import {
  Container,
  Box,
  TextField,
  Button,
  Typography,
  IconButton,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { DndContext, closestCenter } from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';
import { SortableContext, rectSortingStrategy } from '@dnd-kit/sortable';
import { restrictToVerticalAxis, restrictToParentElement } from '@dnd-kit/modifiers';
import SortableGalleryItem from './Gallery/SortableGalleryItem';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {
  GET_SLIDER_PHOTOS,
} from '../graphql/queries'; // Keep query imports here
import {
  CREATE_SLIDER_PHOTO,
  UPDATE_SLIDER_PHOTO,
} from '../graphql/mutations'; // Import mutations from mutations.js

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    background: {
      default: '#121212',
      paper: '#1D1D1D',
    },
    text: {
      primary: '#ffffff',
    },
  },
});

const DynamicSlider = () => {
  const [photos, setPhotos] = useState([]);
  const [lightbox, setLightbox] = useState(null);

  const { loading, error, data } = useQuery(GET_SLIDER_PHOTOS);

  useEffect(() => {
    if (data && data.sliderPhotos) {
      setPhotos(data.sliderPhotos.edges.map(edge => edge.node));
    }
  }, [data]);

  useEffect(() => {
    if (lightbox) {
      lightbox.destroy();
    }

    const newLightbox = new PhotoSwipeLightbox({
      gallery: '#dynamic-slider',
      children: 'a',
      pswpModule: () => import('photoswipe'),
      loop: true,
      padding: { top: 50, bottom: 50 },
      showHideOpacity: true,
      bgOpacity: 0.85,
      closeOnVerticalDrag: false,
      clickToCloseNonZoomable: false,
      wheelToZoom: true,
      pinchToClose: false,
    });

    newLightbox.init();
    setLightbox(newLightbox);

    return () => {
      if (newLightbox) {
        newLightbox.destroy();
      }
    };
  }, [lightbox, photos]);

  const [createSliderPhoto] = useMutation(CREATE_SLIDER_PHOTO, {
    refetchQueries: [{ query: GET_SLIDER_PHOTOS }],
    onError: (error) => console.error('Error adding photo:', error),
  });

  const [updateSliderPhoto] = useMutation(UPDATE_SLIDER_PHOTO, {
    refetchQueries: [{ query: GET_SLIDER_PHOTOS }],
    onError: (error) => console.error('Error updating photo:', error),
  });

  const handleAddPhoto = () => {
    createSliderPhoto({
      variables: { input: { title: 'New Photo', description: '', image: '', order: photos.length + 1 } },
    });
  };

  const handleUpdatePhoto = (id, values) => {
    updateSliderPhoto({ variables: { id, input: values } });
  };

  const handleOnDragEnd = (event) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      setPhotos((items) => {
        const oldIndex = items.findIndex((item) => item.id === active.id);
        const newIndex = items.findIndex((item) => item.id === over.id);
        const newItems = arrayMove(items, oldIndex, newIndex);

        return newItems;
      });
    }
  };

  if (loading) return <Typography>Loading...</Typography>;
  if (error) return <Typography color="error">Error loading photos</Typography>;

  return (
    <ThemeProvider theme={darkTheme}>
      <Container>
        <Typography variant="h4" gutterBottom>
          Dynamic Slider
        </Typography>
        <Box mb={4}>
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={handleAddPhoto}
          >
            Add Photo
          </Button>
        </Box>

        <Formik
          initialValues={{ photos }}
          onSubmit={(values) => {
            values.photos.forEach((photo) =>
              handleUpdatePhoto(photo.id, photo)
            );
          }}
        >
          {({ values, handleChange }) => (
            <Form>
              <DndContext
                collisionDetection={closestCenter}
                onDragEnd={handleOnDragEnd}
                modifiers={[restrictToVerticalAxis, restrictToParentElement]}
              >
                <SortableContext
                  items={values.photos.map((photo) => photo.id)}
                  strategy={rectSortingStrategy}
                >
                  <Swiper
                    modules={[Navigation, Pagination, Autoplay]}
                    spaceBetween={50}
                    slidesPerView={1}
                    navigation
                    pagination={{ clickable: true }}
                    autoplay={{ delay: 2500, disableOnInteraction: false }}
                    id="dynamic-slider"
                  >
                    {values.photos.map((photo, index) => (
                      <SwiperSlide key={photo.id}>
                        <SortableGalleryItem key={photo.id} id={photo.id}>
                          <Box
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                          >
                            <a
                              href={photo.url}
                              data-pswp-width="1024"
                              data-pswp-height="768"
                              data-pswp-title={photo.title}
                            >
                              <img
                                src={photo.url}
                                alt={photo.title}
                                style={{ width: '100%', height: 'auto' }}
                                loading="lazy"
                              />
                            </a>
                            <Field
                              name={`photos[${index}].title`}
                              as={TextField}
                              label="Title"
                              variant="outlined"
                              fullWidth
                              value={photo.title}
                              onChange={handleChange}
                              sx={{ mt: 2, mb: 1 }}
                              inputProps={{ 'aria-label': 'photo title' }}
                            />
                            <Field
                              name={`photos[${index}].description`}
                              as={TextField}
                              label="Description"
                              variant="outlined"
                              fullWidth
                              multiline
                              rows={2}
                              value={photo.description}
                              onChange={handleChange}
                              sx={{ mb: 2 }}
                              inputProps={{ 'aria-label': 'photo description' }}
                            />
                            <Box>
                              <IconButton
                                color="secondary"
                                onClick={() => handleUpdatePhoto(photo.id, { ...photo, description: '' })}
                                aria-label={`Delete ${photo.title}`}
                              >
                                <DeleteIcon />
                              </IconButton>
                              <IconButton
                                color="primary"
                                onClick={() =>
                                  handleUpdatePhoto(photo.id, {
                                    title: photo.title,
                                    description: photo.description,
                                  })
                                }
                                aria-label={`Edit ${photo.title}`}
                              >
                                <EditIcon />
                              </IconButton>
                            </Box>
                          </Box>
                        </SortableGalleryItem>
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </SortableContext>
              </DndContext>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                sx={{ mt: 4 }}
              >
                Save Changes
              </Button>
            </Form>
          )}
        </Formik>
      </Container>
    </ThemeProvider>
  );
};

export default DynamicSlider;
